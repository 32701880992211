// eslint-disable-next-line import/named
import { USER_ROLE } from '@/constants/static.json'
import { defaultDashboard, CompanyOrProjectAdminDashboard } from './dashboard'

// eslint-disable-next-line import/prefer-default-export
export const resolveMenu = userRoleId => {
  switch (userRoleId) {
    case USER_ROLE.ADMIN:
      return [...defaultDashboard]
    case USER_ROLE.COMPANY_ADMIN:
      return [...CompanyOrProjectAdminDashboard]
    case USER_ROLE.USER:
      return [...CompanyOrProjectAdminDashboard]
    default:
      return []
  }
}
