export const defaultDashboard = [
  {
    title: 'Dashboards',
    icon: 'HomeIcon',
    tag: '2',
    tagVariant: 'light-warning',
    children: [
      {
        title: 'Analytics',
        route: 'dashboard-analytics',
        icon: 'PieChartIcon',
      },
      {
        title: 'Overview',
        route: 'dashboard-overview',
        icon: 'LayoutIcon',
      },
    ],
  },
]

export const CompanyOrProjectAdminDashboard = [
  {
    title: 'Dashboards',
    icon: 'HomeIcon',
    tag: '1',
    tagVariant: 'light-warning',
    children: [
      {
        title: 'Overview',
        route: 'dashboard-detail-view',
        icon: 'LayoutIcon',
      },
    ],
  },
]
